import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
import { CommonModalPromptComponent } from '../../components/modal/common-modal-prompt/common-modal-prompt.component';
import { DeleteModalPromptComponent } from '../../components/modal/delete-modal-prompt/delete-modal-prompt.component';
import { SuccessModalPromptComponent } from '../../components/modal/success-modal-prompt/success-modal-prompt.component';
import { WarningModalPromptComponent } from '../../components/modal/warning-modal-prompt/warning-modal-prompt.component';
import { RegisterModalPromptComponent } from '../../components/modal/register-modal-prompt/register-modal-prompt.component';
import { CommentsModalPromptComponent } from '../../components/modal/comments-modal-prompt/comments-modal-prompt.component';
import { SucceedModalPromptComponent } from '../../components/modal/succeed-modal-prompt/succeed-modal-prompt.component';


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  staticText: any = (textConfiguration as any).default;

  constructor(public _dialog: MatDialog) { }

  openCommonDialog(config?: any) {
    return this._dialog.open(config?.component || CommonModalPromptComponent,
      {
        exitAnimationDuration: '50ms',
        height: config?.height,
        panelClass: config?.panelClass || '',
        width: config?.width ? config?.width : '469px',
        data: config?.data || this.staticText?.common?.modal?.common_modal_pop_up,
        disableClose: config?.disableClose ? config?.disableClose : true,
        hasBackdrop: config?.hasBackdrop ? config?.hasBackdrop : true,
      }
    )
  }

  closeDialog(config?: any) {
    return this._dialog.closeAll();
  }

  openSuccessDialog(config?: any) {
    return this._dialog.open(SuccessModalPromptComponent,
      {
        exitAnimationDuration: '50ms',
        height: config?.height,
        width: config?.width ? config?.width : '469px',
        data: config?.data || this.staticText?.common?.modal?.success_modal_pop_up,
        disableClose: config?.disableClose ? config?.disableClose : true,
        hasBackdrop: config?.hasBackdrop ? config?.hasBackdrop : true,
      }
    )
  }
  openSucceedDialog(config?: any) {
    return this._dialog.open(SucceedModalPromptComponent,
      {
        exitAnimationDuration: '50ms',
        height: config?.height,
        width: config?.width ? config?.width : '560px',
        data: config?.data || this.staticText?.common?.modal?.success_modal_pop_up,
        disableClose: config?.disableClose ? config?.disableClose : true,
        hasBackdrop: config?.hasBackdrop ? config?.hasBackdrop : true,
      }
    )
  }

  

  openWarningDialog(config?: any) {
    return this._dialog.open(WarningModalPromptComponent,
      {
        exitAnimationDuration: '50ms',
        height: config?.height,
        width: config?.width ? config?.width : '469px',
        data: config?.data || this.staticText?.common?.modal?.warning_modal_pop_up,
        disableClose: config?.disableClose ? config?.disableClose : true,
        hasBackdrop: config?.hasBackdrop ? config?.hasBackdrop : true,
      }
    )
  }

  openDeleteDialog(config?: any) {
    return this._dialog.open(DeleteModalPromptComponent,
      {
        exitAnimationDuration: '50ms',
        height: config?.height,
        width: config?.width ? config?.width : '469px',
        data: config?.data || this.staticText?.common?.modal?.warning_modal_pop_up,
        disableClose: config?.disableClose ? config?.disableClose : true,
        hasBackdrop: config?.hasBackdrop ? config?.hasBackdrop : true,
      }
    )
  }

  openCommentsDialog(config?: any) {
    return this._dialog.open(CommentsModalPromptComponent,
      {
        exitAnimationDuration: '50ms',
        height: config?.height,
        width: config?.width ? config?.width : '469px',
        data: config?.data || this.staticText?.common?.modal?.warning_modal_pop_up,
        disableClose: config?.disableClose ? config?.disableClose : true,
        hasBackdrop: config?.hasBackdrop ? config?.hasBackdrop : true,
      }
    )
  }

  openRegisterDialog(config?: any) {
    return this._dialog.open(RegisterModalPromptComponent,
      {
        exitAnimationDuration: '50ms',
        height: config?.height,
        width: config?.width ? config?.width : '469px',
        data: config?.data || this.staticText?.common?.modal?.warning_modal_pop_up,
        disableClose: config?.disableClose ? config?.disableClose : true,
        hasBackdrop: config?.hasBackdrop ? config?.hasBackdrop : true,
      }
    )
  }
}
